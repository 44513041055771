import React, { useEffect, useState } from "react";
import { NavLink } from 'react-router-dom';

export default function NavBar() {
  const [mobile, setMobile] = useState(false)
  const [mid, setMid] = useState("")

  useEffect(() => {
    window.onscroll = () => {
      setMid(window.scrollY === 0 ? "" : " navbar-mid")
    }
  })

  const showNav = () => { setMobile(false) }

  return (
    <div>
      <header className={"navbar" + mid}>
        <button
          onClick={() => { setMobile(!mobile) }}
          className={"mobile-nav-toggle" + (mobile ? " expanded" : "")}
          aria-controls="primary-nav">
          <span className="sr-only">MENU</span>
        </button>
        <div><NavLink to="./"><h3 className={"navbar-title"}>Leah Jia</h3></NavLink></div>
        <nav>
          <ul id="primary-nav" className={"navbar-links" + (mobile ? " showNav" : "")}>
            <li><NavLink className={"nav-link " + (({ isActive }) => isActive ? "active" : "")} onClick={showNav} to="./">ABOUT</NavLink></li>
            <li><NavLink className={"nav-link " + (({ isActive }) => isActive ? "active" : "")} onClick={showNav} to="/contact">CONTACT</NavLink></li>
            <li><NavLink className={"nav-link " + (({ isActive }) => isActive ? "active" : "")} onClick={showNav} to="https://leahjia.s3.amazonaws.com/resume" target="_blank" >RESUME</NavLink></li>
          </ul>
        </nav>
      </header>
    </div>
  )
}