import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";

import About from "./About.js";
import Footer from "./Footer.js";
import Navbar from "./Navbar.js";
import Contact from "./Contact.js";
import ParticlesBg from "particles-bg";

export default function App() {
  const onSelectMode = (mode) => {
    if (mode === "dark") document.body.classList.add("dark-mode");
    else document.body.classList.remove("dark-mode");
  };
  window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => onSelectMode(e.matches ? "dark" : "light"));
  useEffect(() => {
    window.matchMedia("(prefers-color-scheme: dark)").addEventListener("change", (e) => onSelectMode(e.matches ? "dark" : "light"));
    onSelectMode(window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light");
    return () => {
      window.matchMedia("(prefers-color-scheme: dark)").removeEventListener("change", () => { });
    };
  });

  let browserWidth = window.innerWidth;
  let browserHeight = window.innerHeight;

  let snowConfig = {
    num: [2, 3],
    rps: 0.05,
    radius: [2, 5],
    life: [0, 100],
    v: [0.0001, 0.0001],
    tha: 0,
    rotate: [0, 0],
    alpha: [0.6, 0],
    scale: [1, 0.8],
    position: "all",
    color: ["#d3d1d16d", "rgba(255, 255, 255, 0.8)"],
    cross: "dead",
    random: 5,
    g: 0.3,
    f: [-0.3 * Math.random(), 0],
    onParticleUpdate: (ctx, particle) => {
      if (particle.isNew) {
        particle.p.x = Math.random() * browserWidth;
        particle.p.y = Math.random() * -browserHeight;
        particle.vx = Math.random() * (2 - 1) + 1;
        particle.vy = Math.random() * 1;
        particle.isNew = false;
      }
      ctx.beginPath();
      ctx.arc(particle.p.x, particle.p.y, particle.radius, 0, 2 * Math.PI);
      ctx.fillStyle = particle.color;
      ctx.fill();
      ctx.closePath();
    },
  };

  return (
    <div>
      <ParticlesBg
        type="custom"
        config={snowConfig}
        bg={{
          position: "fixed",
          zIndex: -1,
        }}
      />
      <Navbar />
      <Routes>
        <Route index element={<About />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="*" element={<About />} />
      </Routes>
      <Footer />
    </div>
  );
}
